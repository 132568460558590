@use '../../../theme/colors';

@import '../../../theme/mixins';

.button {
	padding: 12px 24px;
	color: colors.$white;
	font-size: 17px;
	font-weight: 600;

	&:hover {
		color: colors.$white;
	}

	&:focus-visible {
		outline: 2px solid #000;
	}

	&--blue {
		background-color: colors.$ts-color-violet-75;
	}

	&--black {
		background-color: colors.$black;
	}

	&--white,
	&--white-small {
		border: 1px solid colors.$ts-color-gray-100;
		background-color: colors.$white;
		color: colors.$black;

		&:hover {
			color: colors.$black;
		}
	}

	&--white-small {
		@include font-style(16px, 500, 16px, -0.3px);
		background-color: transparent;
		padding: 8px 13px;
	}

	&--img {
		padding: 0;
	}

	&.left {
		padding-left: 50px;
		background-repeat: no-repeat;
		background-position: 24px center;
	}

	&__icon-button {
		@include font-style(15px, 600, 15px, -0.2px);
		background: none;
		padding: 0 0 0 18px;
		color: colors.$black;

		&:hover {
			color: colors.$black;
		}

		&.left {
			padding-left: 18px;
			background-position: left center;
		}
	}

	&.remove {
		background-image: url('../../../assets/images/icon/remove.png');
	}

	&.download {
		background-image: url('../../../assets/images/icon/download.png');
	}

	&.logout {
		background-image: url('../../../assets/images/icon/logout.png');
	}

	&.rightArrowWhite {
		background: colors.$black url('../../../assets/images/icon/arrowRight.png') no-repeat calc(100% - 16px) center;
		padding-right: 44px;
	}
}

.button--black:focus-visible {
	outline: 2px solid orange;
}
