@use '../../theme/colors';
.filter-container {
	position: relative;
	padding-bottom: 39.75%;
	height: 0;
	overflow: hidden;
}
.filter-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.keystat-container {
	position: relative;
	padding-bottom: 50.75%;
	height: 0;
	overflow: hidden;
}

.keystat-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.satisf-container {
	position: relative;
	padding-bottom: 92.75%;
	height: 0;
	overflow: hidden;
}

.satisf-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.outcome-container {
	position: relative;
	padding-bottom: 100.75%;
	height: 0;
	overflow: hidden;
}

.outcome-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.invoice-container {
	position: relative;
	padding-bottom: 140.75%;
	height: 0;
	overflow: hidden;
}

.invoice-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.dashboard__container .tabs .tab-content {
	padding-top: 0px;
	//margin-left:-10px
}

.groupaContainer {
	padding: 50px 60px 50px 60px;
	border-radius: 10px;
	background-color: colors.$ts-color-blue-10;
	margin-top: 10px;
	margin-bottom: 10px;
	//width: 101%;

	& .title {
		color: colors.$ts-color-gray-100;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 45px;
		letter-spacing: -1px;
		padding: 0;
		margin-bottom: 15px;
	}

	& .desc {
		color: colors.$ts-color-gray-75;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
		border-bottom: 1px solid #d6dae7;
		padding: 0px 0px 20px 0px;
	}

	& .jumpTo {
		color: colors.$ts-color-gray-75;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	& .btn-outline-secondary {
		color: colors.$black;
		border: none;
		font-size: 17px;
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: -0.3px;
		padding: 0px;
		margin-right: 10px;
	}

	& .btn-outline-secondary:hover {
		background: none;
	}

	& .filterBtn {
		color: colors.$black;
		border: 1px solid #282a32;
		font-size: 17px;
		font-weight: 600;
		letter-spacing: -0.3px;
		background: transparent;

		img {
			padding: 5px;
		}
	}
}
@media only screen and (max-width: 768px) {
	.groupaContainer {
		padding: 50px 30px 50px 30px;
	}
}
@media only screen and (max-width: 576px) {
	.groupaContainer {
		padding: 50px 20px 50px 20px;
	}
}

// .groupasearchContainer {
// 	justify-content: flex-end;
// 	gap: 1%;
// 	margin-bottom: 5px;
// 	padding-right: 2%;

// 	& .btn-secondary {
// 		border: 1px solid colors.$black;
// 		border-radius: 0;
// 	}

// 	& .filterSearchBtn {
// 		border: 1px solid colors.$ts-color-violet-75;
// 		background-color: colors.$ts-color-violet-75;
// 		color: colors.$white;
// 		font-size: 16px;
// 		font-style: normal;
// 		font-weight: 500;
// 		line-height: 16px;
// 		letter-spacing: -0.3px;
// 		padding: 12px 12px;

// 		&:focus-visible {
// 			outline: 2px solid #000;
// 		}
// 	}

// 	& .filterResetBtn {
// 		border: 1px solid colors.$ts-color-gray-100;
// 		background-color: colors.$white;
// 		color: colors.$black;
// 		font-size: 16px;
// 		font-style: normal;
// 		font-weight: 500;
// 		line-height: 16px;
// 		letter-spacing: -0.3px;
// 		padding: 12px 12px;

// 		&:focus-visible {
// 			outline: 2px solid #000;
// 		}
// 	}

// 	@media only screen and (max-width: 576px) {
// 			& .filterSearchBtn {
// 				padding: 8px 8px;
// 			}

// 			& .filterResetBtn {
// 				padding: 8px 8px;
// 			}
// 	}
// }

// @media only screen and (max-width: 768px) {
// 	.groupaContainer {
// 		padding: 20px;
// 		& .title {
// 			font-size: 27px;
// 			font-style: normal;
// 			font-weight: 700;
// 			line-height: 34px;
// 			letter-spacing: -0.5px;
// 		}
// 	}
// 	.reportClassC_filter {
// 		height: 1518px;
// 	}
// 	.reportClassC_key {
// 		height: 2289px;
// 	}
// 	.reportClassC_satisf {
// 		height: 3539px;
// 	}
// 	.reportClassC_outcome {
// 		height: 2904px;
// 	}
// 	.reportClassC_invo {
// 		height: 2900px;
// 	}
// }

// @media only screen and (max-width: 576px) {
// 	.groupaContainer {
// 		padding: 20px;

// 		& .title {
// 			font-size: 27px;
// 			font-style: normal;
// 			font-weight: 700;
// 			line-height: 34px;
// 			letter-spacing: -0.5px;
// 		}
// 	}

// 	.reportClassC_filter {
// 		height: 154vh;
// 	}

// 	.reportClassC_key {
// 		height: 226vh;
// 	}

// 	.reportClassC_satisf {
// 		height: 343vh;
// 	}

// 	.reportClassC_outcome {
// 		height: 282vh;
// 	}

// 	.reportClassC_invo {
// 		height: 281vh;
// 	}
// }

// @media only screen and (max-width: 540px) {
// 	.reportClassC_filter {
// 		height: 1051px;
// 	}

// 	.reportClassC_key {
// 		height: 1585px;
// 	}

// 	.reportClassC_satisf {
// 		height: 2453px;
// 	}

// 	.reportClassC_outcome {
// 		height: 2021px;
// 	}

// 	.reportClassC_invo {
// 		height: 2020px;
// 	}
// }

// @media only screen and (max-width: 430px) {
// 	.reportClassC_filter {
// 		height: 845px;
// 	}

// 	.reportClassC_key {
// 		height: 1259px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1931px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1597px;
// 	}

// 	.reportClassC_invo {
// 		height: 1595px;
// 	}
// }

// @media only screen and (max-width: 425px) {
// 	.reportClassC_filter {
// 		height: 811px;
// 	}

// 	.reportClassC_key {
// 		height: 1227px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1847px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1527px;
// 	}

// 	.reportClassC_invo {
// 		height: 1526px;
// 	}
// }

// @media only screen and (max-width: 414px) {
// 	.reportClassC_filter {
// 		height: 815px;
// 	}

// 	.reportClassC_key {
// 		height: 1211px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1855px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1535px;
// 	}

// 	.reportClassC_invo {
// 		height: 1534px;
// 	}
// }

// @media only screen and (max-width: 390px) {
// 	.reportClassC_filter {
// 		height: 769px;
// 	}

// 	.reportClassC_key {
// 		height: 1140px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1741px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1442px;
// 	}

// 	.reportClassC_invo {
// 		height: 1441px;
// 	}
// }

// @media only screen and (max-width: 375px) {
// 	.reportClassC_filter {
// 		height: 745px;
// 	}

// 	.reportClassC_key {
// 		height: 1071px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1600px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1326px;
// 	}

// 	.reportClassC_invo {
// 		height: 1325px;
// 	}
// }

// @media only screen and (max-width: 360px) {
// 	.reportClassC_filter {
// 		height: 713px;
// 	}

// 	.reportClassC_key {
// 		height: 1051px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1600px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1326px;
// 	}

// 	.reportClassC_invo {
// 		height: 1325px;
// 	}
// }

// @media only screen and (max-width: 320px) {
// 	.reportClassC_filter {
// 		height: 575px;
// 	}

// 	.reportClassC_key {
// 		height: 826px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1219px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1017px;
// 	}

// 	.reportClassC_invo {
// 		height: 1016px;
// 	}
// }

// @media only screen and (max-width: 280px) {
// 	.reportClassC_filter {
// 		height: 563px;
// 	}

// 	.reportClassC_key {
// 		height: 813px;
// 	}

// 	.reportClassC_satisf {
// 		height: 1219px;
// 	}

// 	.reportClassC_outcome {
// 		height: 1017px;
// 	}

// 	.reportClassC_invo {
// 		height: 1016px;
// 	}
// }
