@use '../../theme/colors';

.team-member-tile {
	&__image {
		position: relative;

		& img {
			width: 100%;
			height: 120px;
			background-color: colors.$ts-color-blue-10;
			border-radius: 5px;
		}

		@media (min-width: 1400px) {
			& img {
				width: 100%;
				//height: 150px;
				height: 142px;
			}
		}

		@media (max-width: 1199px) {
			& img {
				width: 100%;
				height: 100%;
			}
		}

		@media (max-width: 576px) {
			//padding-left: 0;
			& img {
				width: 85px;
				height: 85px;
			}
		}
		@media (max-width: 425px) {
			//padding-left: 0;
			& img {
				width: 65px;
				height: 65px;
			}
		}
	}

	&__view {
		$p: &;

		background-color: colors.$white;
		position: absolute;
		right: calc(var(--bs-gutter-x) * 0.5);
		bottom: 0;
		padding: 5px 10px;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		border-top-left-radius: 10px;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			position: absolute;
			background-color: transparent;
			width: 8px;
			box-shadow: 0 -8px 0 0 #fff;
		}

		&::before {
			left: -8px;
			bottom: 0px;
			height: 50px;
			border-top-left-radius: 8px;
			transform: rotate(180deg);
		}

		&::after {
			right: 7px;
			top: -15px;
			height: 22px;
			border-top-right-radius: 8px;
			transform: rotate(90deg);
		}

		&-text {
			display: none;
		}

		&:hover {
			#{$p}-sign {
				display: none;
			}
			#{$p}-text {
				display: inline-block;
			}
		}
	}

	&__name {
		font-size: 19px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: -0.1px;

		@media (min-width: 576px) {
			margin-top: 20px;
		}
	}

	&__position {
		color: colors.$ts-color-gray-75;
		margin-top: 5px;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.1px;
	}

	@media (max-width: 768px) {
		&__button {
			padding-left: 0;
		}
	}
	@media (max-width: 576px) {
		& button {
			padding: 6px 16px;
		}
	}
	@media (max-width: 576px) {
		& button {
			padding: 6px 16px;
		}
	}
}
