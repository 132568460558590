#form-file-upload {
	height: 16rem;
	// width: 33rem;
	max-width: 100%;
	text-align: center;
	position: relative;
}

#input-file-upload {
	display: none;
}

#label-file-upload {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 2px;
	border-radius: 1rem;
	border-style: dashed;
	border-color: #cbd5e1;
	background-color: #f8fafc;
}

#label-file-upload.drag-active {
	background-color: #ffffff;
}

.upload-button {
	cursor: pointer;
	height: 46px;
	padding: 0px 24px;
	border-radius: 100px;
	border: 1px solid #282a32;
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: -0.3px;
}

.upload-button:hover {
	text-decoration-line: none;
}

#drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.disableFileUpload {
	opacity: 0.7;
	pointer-events: none;
}
