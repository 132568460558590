@use '../../../theme/colors';

.ag-theme-alpine.grid {
	--ag-borders: none;
	--ag-header-column-resize-handle-display: none;
	--ag-header-foreground-color: #67696f;
	--ag-header-background-color: #f1f3f9;

	width: '100%';
	margin-top: 40px;
	font-weight: 500;

	.ag-header-cell {
		border-bottom: 1px solid colors.$ts-color-gray-30;
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 0.2px;
		text-transform: uppercase;
	}

	.ag-row-odd {
		background-color: colors.$ts-color-blue-5;
	}
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	padding-left: 8px !important;
	padding-right: 8px !important;
}
.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.pendingColor {
	background: rgba(255, 122, 18, 0.6);
}

.approvedColor {
	background: #a6eb8c;
}

.rejectedColor {
	background: #fc5c7d;
}

.inprogressColor {
	background: rgba(255, 122, 18, 0.6);
}
