@import '../theme/mixins';
@import '../theme/breakpoints';

h1 {
	@include font-style(30px, 700, 34px, -0.5px);

	@include md {
		@include font-style(80px, 700, 85px, -3px);
	}
}

h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 34px;
	letter-spacing: -0.5px;

	@media (min-width: 768px) {
		font-size: 40px;
		line-height: 45px;
		letter-spacing: -1px;
	}
}

h3 {
	margin-bottom: 12px;
	font-size: 22px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.2px;

	@media (min-width: 768px) {
		@include font-style(30px, 700, 34px, -0.5px);
	}
}

h4 {
	@include font-style(19px, 700, 24px, -0.1px);

	@media (min-width: 768px) {
		@include font-style(22px, 700, 28px, -0.2px);
	}
}
