@use '../../theme/colors';

.referralsContainer {
	& .leftPanel {
		& .title {
			font-size: 27px;
			font-weight: 700;
			line-height: 34px;
			letter-spacing: -0.5px;
			color: colors.$ts-color-gray-100;
		}

		& .description {
			color: colors.$ts-color-gray-75;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px;
		}

		& .downloadReferralBtn {
			border-radius: 100px;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: colors.$white;
			color: colors.$black;
			font-size: 17px;
			font-weight: 600;
			letter-spacing: -0.3px;

			img {
				padding: 5px;
			}

			.downloadReferralBtn:before {
				//content: "\f017";
				background-image: url('../../assets/images/DownlodIcon.png');
				display: inline-block;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			&:focus-visible {
				outline: 2px solid #000 !important;
			}
		}
	}

	& .rightPanel {
		padding: 40px;
		border-radius: 20px;
		background: colors.$ts-color-blue-10;

		& .title {
			font-size: 27px;
			font-weight: 700;
			line-height: 34px;
			letter-spacing: -0.5px;
			color: colors.$ts-color-gray-100;
		}

		& .description {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			/* 150% */
			letter-spacing: -0.2px;
		}

		& .newReferralBtn {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: -0.3px;
		}
	}

	& .requestList {
		margin-top: 50px;
		margin-bottom: 50px;

		& .reqTitle {
			color: colors.$black;
			font-size: 27px;
			font-weight: 700;
			line-height: 45px;
			letter-spacing: -1px;
		}

		& .viewText {
			text-align: center;
			font-size: 16px;
			font-weight: 600;
		}

		& .gridStatus {
			font-size: 12px;
			font-weight: 600;
			line-height: 14px;
			color: colors.$black;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 22px;
			width: auto;
			padding: 4px 4px;
			margin-top: 13px;
			border-radius: 2px;
		}

		& .viewArrow {
			vertical-align: text-bottom;
			margin-left: 8px;
		}

		& .statusIcon {
			vertical-align: text-bottom;
			padding: 4px;
		}

		& .ag-root-wrapper {
			border-right: none;
			border-left: none;
			border-bottom: none;
		}

		& .gridArea {
			width: 100%;
			height: 500px;
		}

		.ag-header-cell-label {
			justify-content: left;
		}

		.addedOn .ag-header-cell-label {
			justify-content: right;
		}
	}
}

/* Media queries for screen less than 576 Starts */
@media only screen and (max-width: 576px) {
	.referralsContainer .leftPanel .title {
		font-size: 22px;
	}

	.referralsContainer .rightPanel .title {
		font-size: 22px;
	}

	.referralsContainer .requestList .reqTitle {
		font-size: 30px;
	}
	.downloadReferralBtn {
		width: 100%;
	}
}
