@use '../../../theme/colors';

.modal {
	&__close-btn {
		top: -50px;
		right: -3px;
		position: absolute;

		@media (min-width: 768px) {
			top: 15px;
			right: 15px;
		}
	}

	.modal-content {
		border-radius: 1.3rem;
		border: none;
		margin-top: 50px;
		padding: 24px;

		@media (min-width: 768px) {
			margin-top: 0;

			& .bottom {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 55px;
				border-radius: 0 0 1.3rem 1.3rem;
				overflow: hidden;

				&::after {
					content: '';
					display: block;
					border-bottom: 55px solid;
					border-image: linear-gradient(
							165deg,
							rgb(86, 189, 237, 0.8),
							rgb(166, 235, 140, 0.8),
							rgb(166, 235, 140, 0.5),
							rgb(86, 189, 237, 0.7)
						)
						30;
				}
			}
		}

		.modal-header {
			justify-content: center;
			border: none;

			.modal-title {
				font-size: 30px;
				font-style: normal;
				font-weight: 700;
				line-height: 45px;
				letter-spacing: -1px;
			}
		}

		.modal-body {
			color: colors.$ts-color-gray-100;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.2px;
		}

		.modal-footer {
			justify-content: center;
			border: none;
			.confirmBtn {
				height: 44px;
				padding: 0px 24px;
				justify-content: center;
				align-items: center;
				border: 1px solid colors.$ts-color-violet-75;
				border-radius: 100px;
				background: colors.$ts-color-violet-75;
				color: colors.$white;
				text-align: center;
				font-size: 17px;
				font-style: normal;
				font-weight: 600;
				line-height: 19px;
				letter-spacing: -0.3px;
			}

			.cancelBtn {
				height: 44px;
				padding: 0px 24px;
				justify-content: center;
				align-items: center;
				border: 1px solid colors.$ts-color-gray-100;
				border-radius: 100px;
				background: transparent;
				color: colors.$black;
				text-align: center;
				font-size: 17px;
				font-style: normal;
				font-weight: 600;
				line-height: 19px;
				letter-spacing: -0.3px;
			}
		}
	}

	&--close-button {
		@media (min-width: 768px) {
			.modal-content {
				padding: 55px 55px 110px 55px;
			}
		}
	}
}
