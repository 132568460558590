.tabs {
	& .nav-item {
		margin-left: 30px;

		&:first-child {
			margin-left: 0;
		}
	}

	& .nav-link {
		padding: 0 0 12px;
		border: none;
		border-bottom: 2px solid transparent;
		color: colors.$ts-color-gray-100;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
	}

	& .nav-link.active {
		border-bottom: 2px solid colors.$ts-color-violet-75;
		color: colors.$ts-color-violet-75;
	}

	& .tab-content {
		padding-top: 40px;
	}
}
