.custom-tooltip {
	width: 100%;
	border: 1px solid cornflowerblue;
	overflow: hidden;
}

.custom-tooltip p {
	margin: 5px;
	white-space: nowrap;
}

.custom-tooltip p:first-of-type {
	font-weight: bold;
}
