@mixin md {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin sm {
	@media only screen and (max-width: 767px) {
		@content;
	}
}
