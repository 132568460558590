@use '../../theme/colors';

@import '../../theme/breakpoints.scss';

.footer {
	background-color: colors.$black;
	padding: 30px 0;
	color: colors.$white;

	.content {
		color: colors.$white;
		text-align: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 17px;
	}
	.copyright {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 17px;
		text-align: center;
	}
	.navbar-light .navbar-nav .nav-link {
		color: colors.$white;
	}
	.footer-link-toggle {
		display: none;
	}
	&__nav {
		display: flex;
		justify-content: center;
		gap: 3%;
		list-style: none;
		li a {
			color: colors.$white;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.1px;
			text-decoration: none;
		}
	}

	a {
		transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
		opacity: 1;
	}

	a:hover {
		opacity: 0.5;
	}

	.FooterManpowerGroupLogo {
		//max-height: 3.75rem;
		height: 65px;
		width: auto;
	}
	.FooterManpowerLogo {
		//max-height: 5rem;
		height: 90px;
		width: auto;
	}
	.FooterExperissLogo {
		//max-height: 5rem;
		height: 100px;
		width: auto;
	}
	.FooterTalentSolutionLogo {
		//max-height: 5rem;
		height: 100px;
		width: auto;
	}

	@include sm {
		.footer-link-toggle {
			display: inline-flex;
		}
		.footer-link-toggle::after {
			content: url('../../assets/images/DownArrowWhite.png');
			border: none;
			width: 10px;
			height: auto;
			margin-left: 0.4rem;
			vertical-align: middle;
		}

		.footer-link-toggle.show::after {
			content: url('../../assets/images/UpArrowWhite.png');
			border: none;
			width: 10px;
			height: auto;
			margin-left: 0.4rem;
			vertical-align: middle;
		}
		.footer__nav {
			display: none;
		}
		.footer__nav.show {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.mobViewlogo {
			display: none;
		}

		.mobViewlogo.show {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 10px;
			.FooterManpowerLogo {
				//max-height: 5rem;
				height: 75px;
				width: auto;
			}

			.FooterExperissLogo {
				//max-height: 5rem;
				height: 80px;
				width: auto;
			}

			.FooterTalentSolutionLogo {
				//max-height: 5rem;
				height: 80px;
				width: auto;
			}
		}
	}
}
