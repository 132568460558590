@use '../../../theme/colors';

@import '../../../theme/mixins';

.label {
	@include font-style(16px, 600, 24px, -0.1px);
	width: 100%;
	margin-bottom: 8px;

	.disabled & {
		color: colors.$ts-color-gray-30;
	}
}
