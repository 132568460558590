@import '../../../theme/breakpoints';

.resources {
	margin-top: 24px;

	@include md {
		margin-top: 58px;
	}

	&__load-more button {
		width: 285px;
	}

	.filterd-resources-summary + & {
		@include md {
			margin-top: 32px;
		}
	}
}

.filterd-resources-summary {
	margin-top: 32px;
	hr {
		margin: 32px 0;
	}
}

@media (min-width: 578px) and (max-width: 768px) {
	.resources {
		padding: 35px;
	}
}
