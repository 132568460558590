@use '../../../theme/spacing';

@import '../../../theme/breakpoints';

.home-dashboard {
	margin-top: spacing.$vs-between-comp-sm;

	@include md {
		margin-top: spacing.$vs-between-comp-md;
	}

	&__title {
		font-size: 30px;
		font-weight: 700;
		line-height: 34px;
		letter-spacing: -0.5px;
	}

	&__report {
		//height: 535px;
		height: 75vh;
	}

	.home-dashboard-container {
		position: relative;
		padding-bottom: 20.75%;
		height: 0;
		overflow: hidden;
		z-index: 0;
	}

	.home-dashboard-container iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
}
@media screen and (min-width: 2560px) {
	.home-dashboard {
		&__report {
			height: 420px;
		}
	}
}
@media screen and (min-width: 1920px) {
	.home-dashboard {
		&__report {
			height: 80vh;
		}
	}
}
@media screen and (min-width: 1400px) {
	.home-dashboard {
		&__report {
			height: 58vh;
		}
	}
}

@media screen and (max-width: 1286px) {
	.home-dashboard {
		&__report {
			height: 80vh;
		}
	}
}

@media (min-width: 992px) and (max-width: 1024px) {
	.home-dashboard {
		&__report {
			height: 52vh;
		}
	}
}
@media only screen and (min-width: 769px) and (max-width: 991px) {
	.home-dashboard {
		&__report {
			height: 25vh;
		}
	}
}
@media only screen and (max-width: 768px) {
	.home-dashboard {
		&__report {
			height: 3222px;
		}
	}
}

@media only screen and (max-width: 540px) {
	.home-dashboard {
		&__report {
			height: 2206px;
		}
	}
}

@media only screen and (max-width: 430px) {
	.home-dashboard {
		&__report {
			height: 1715px;
		}
	}
}

@media only screen and (max-width: 414px) {
	.home-dashboard {
		&__report {
			height: 1644px;
		}
	}
}

@media only screen and (max-width: 412px) {
	.home-dashboard {
		&__report {
			height: 1635px;
		}
	}
}

@media only screen and (max-width: 390px) {
	.home-dashboard {
		&__report {
			height: 1537px;
		}
	}
}

@media only screen and (max-width: 376px) {
	.home-dashboard {
		&__report {
			height: 1471px;
		}
	}
}

@media only screen and (max-width: 360px) {
	.home-dashboard {
		&__report {
			height: 1403px;
		}
	}
}

@media only screen and (max-width: 280px) {
	.home-dashboard {
		&__report {
			height: 1046px;
		}
	}
}
