@use '../../theme//colors';
@import '../../theme/breakpoints.scss';

.banner {
	background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);
	padding: 0;
	border-bottom-right-radius: 20px;

	&__container {
		background: url('../../assets/images/bannerUser1.png') no-repeat center right;
		padding: 100px;
		border-bottom-right-radius: 20px;
	}

	&__summary {
		margin-top: 40px;
		padding-left: 20px;
		font-size: 50px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px;
		letter-spacing: -0.5px;
	}
}

.tbanner {
	background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);
	border-bottom-right-radius: 20px;
	padding: 0;

	@include md {
		&__content {
			height: 275px;
			margin-left: 0.25rem;
		}
	}

	&__imagecontainer {
		border-bottom-right-radius: 20px;
		overflow: hidden;

		& img {
			height: 275px;
		}
	}

	&__userimage {
		border-bottom-right-radius: 20px;
	}

	&__summary {
		margin-bottom: 0;
		bottom: 35px;
		// left: 6px;
	}
}

@media (max-width: 1280px) {
	.tbanner {
		//padding: 4rem 0rem 2.5rem 0.6rem;
		background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);
		&__content {
			height: 200px;
			margin-left: 0;
		}

		&__summary {
			position: relative !important;
			bottom: 0;
			font-size: 55px;
			line-height: 280px;
		}

		&__imagecontainer img {
			height: 200px;
		}
	}
}

@media (max-width: 1024px) {
	.tbanner {
		background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);
	}
}

@media (min-width: 769px) and (max-width: 991px) {
	.tbanner {
		padding: 4rem 0rem 2.5rem;
		background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);

		&__content {
			height: 85px;
			margin-left: 0;
		}

		&__summary {
			position: relative !important;
			bottom: 0;
			font-size: 50px;
			line-height: 135px;
		}
	}
}

@media (max-width: 768px) {
	.banner {
		background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);
		&__summary {
			padding: 15px 0px;
			font-size: 30px;
		}
	}

	.tbanner {
		padding: 4rem 0rem 2.5rem;
		background-image: linear-gradient(to right, colors.$banner-color-green, colors.$ts-color-green-25);

		&__content {
			height: 75px;
			margin-left: 0;
		}

		&__summary {
			font-size: 36px;
			line-height: 130px;
			position: relative !important;
			bottom: 0;
		}
	}
}

@media (max-width: 576px) {
	.tbanner {
		&__content {
			height: 25px;
		}
		&__summary {
			font-size: 30px;
			line-height: 30px;
			position: relative !important;
			bottom: 0;
		}
	}
}
