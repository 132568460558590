@use '../../../theme/spacing';
@use '../../../theme/colors';
@import '../../../theme/typography';
@import '../../../styles/global.scss';

.account-team-listing {
	@media (max-width: 768px) {
		hr {
			margin: 20px 0;
		}
	}
}

.member-bio {
	hr {
		margin: 30px 0 20px;
	}
	&__img {
		width: 100%;
		border-radius: 5px;
	}
	&__name {
		color: colors.$black;
		font-size: 40px;
		font-weight: 700;
		line-height: 45px;
		letter-spacing: -1px;
	}
	&__email-title,
	&__phone-title {
		color: colors.$black;
		margin-top: 20px;
		font-weight: 600;
		letter-spacing: -0.1px;
	}
	&__bio-title {
		color: colors.$black;
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 700;
		line-height: 34px;
		letter-spacing: -0.5px;
	}
}
