.confirm-details {
	// .leftimg {
	//         height: 100vh;
	//         width: 80%;
	// }
	.vs-comp {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 768px) {
	.confirm-details {
		padding: 0;
	}
}
