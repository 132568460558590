@font-face {
	font-family: 'Inter';
	/*Can be any text*/
	src:
		local('Inter'),
		url('../assets/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter-Light';
	/*Can be any text*/
	src:
		local('Inter-Light'),
		url('../assets/fonts/static/Inter-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter-Regular';
	/*Can be any text*/
	src:
		local('Inter-Regular'),
		url('../assets/fonts/static/Inter-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter-Medium';
	/*Can be any text*/
	src:
		local('Inter-Medium'),
		url('../assets/fonts/static/Inter-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Inter-MediumBold';
	/*Can be any text*/
	src:
		local('Inter-SemiBold'),
		url('../assets/fonts/static/Inter-SemiBold.ttf') format('truetype');
}
