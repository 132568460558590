.containerErrorHandler {
	height: 100vh;
	overflow: hidden;

	& .errorContent {
		width: 80vw;
	}
	& .pageDescription {
		color: #000;
		text-align: center;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: -0.3px;
	}
	& .backtohome {
		text-align: center;
	}
}
