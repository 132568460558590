@use '../../../theme/colors';
@import '../../../theme/breakpoints';
.notifyContainer {
	top: 35px;
	position: absolute;
	right: 0;
	width: 30%;
	display: none;
	&.show {
		display: block;
	}
	&__slide-in {
		z-index: 10;
		position: absolute;
		overflow: hidden;
		margin-top: 5px;
		border-radius: 8px;
		box-shadow: -5px 13px 54px -7px rgba(0, 0, 0, 0.54);
		-webkit-box-shadow: -5px 13px 54px -7px rgba(0, 0, 0, 0.54);
		-moz-box-shadow: -5px 13px 54px -7px rgba(0, 0, 0, 0.54);
	}

	&__slide-in.from-left {
		left: 0;
	}

	&__slide-in.from-right {
		right: 0;
	}

	&__slide-in-content {
		min-width: 650px;
		background: #fff;
		transition: transform 0.5s ease;
		border: 1px solid #dadada;
	}

	&__slide-in.from-left .slide-in-content {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}

	&__slide-in.from-right .slide-in-content {
		display: none;
	}

	&__slide-in.show .slide-in-content {
		display: block;
	}

	&__notifyHead {
		padding: 5px 20px;
		margin-top: 10px;
		border-bottom: 1px solid #dadada;
	}

	&__notifyTitle {
		color: #67696f;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
	}

	&__readAll {
		color: #5c4bb9;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		cursor: pointer;
	}

	&__readAll:hover {
		color: #67696f;
	}

	&__notificationContainer {
		// min-height: 300px;
		max-height: 600px;
		overflow-y: auto;
		@media only screen and (max-width: 576px) {
			max-height: 400px;
		}
	}

	&__notificationList {
		margin: 16px 10px 0px 10px;
		border-bottom: 1px solid #dadada;
		cursor: pointer;
	}

	&__notifyMsg {
		color: #67696f;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
		margin-bottom: 5px;
	}

	&__notifyTime {
		color: #939498;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
	}
	&__notifyDate {
		background-color: #efefef;
		color: #000;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
		margin-left: 12%;
		width: auto;
		padding: 5px 10px;
		margin-bottom: 10px;
		border-radius: 5px;
	}
	&__blueDot {
		height: 10px;
		width: 10px;
		background-color: #5c4bb9;
		border-radius: 50%;
		display: inline-block;
	}

	&__greyDot {
		height: 10px;
		width: 10px;
		background-color: transparent;
		border-radius: 50%;
		display: inline-block;
		border: 1px solid #939498;
	}

	&__viewAll {
		color: #5c4bb9;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		margin: 10px 0px 10px 0px;
		cursor: pointer;
	}
	&__viewAll:hover {
		color: #67696f;
	}
	&__viewAll .disable {
		color: #67696f;
		cursor: default;
	}
	&__templateLink {
		text-decoration: underline;
	}
}

@media screen and (max-width: 2559px) {
	.notifyContainer__slide-in-content {
		min-width: 300px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.notifyContainer__slide-in-content {
		min-width: 394px;
	}
}

@media only screen and (max-width: 991px) {
	.notifyContainer {
		&__slide-in {
			margin: auto;
			position: relative;
		}
	}
	.notifyContainer__slide-in-content {
		min-width: 500px;
		max-width: 550px;
	}
	.notifyContainer__notifyTitle {
		font-size: 16px;
	}
	.notifyContainer__viewAll {
		min-height: 65px;
		line-height: 50px;
	}
	.notifyContainer__notifyMobileHead {
		//border-bottom: 1px solid #dadada;
	}
	.notifyContainer__notifyHead {
		padding: 5px 20px;
	}
	.notifyClose {
		border: 2px solid #fff;
		color: #fff;
		background: #000;
		margin: 5px 12px;
	}
	.notifyContainer.show {
		position: fixed;
		z-index: 2800;
		top: 0;
		left: 0;
		width: 100vw;
		display: flex;
		align-items: center;
		min-height: calc(100% - 1rem);
	}
	.notifyBackdrop.show {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2800;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0.5;
	}
	.slide-in.from-right {
		left: 0;
		width: 100%;
	}
	.notifyContainer__notificationContainer {
		max-height: 400px;
	}
}

@media screen and (max-width: 576px) {
	.notifyContainer__slide-in-content {
		min-width: 350px;
		max-width: 350px;
	}
	.notifyContainer__notifyHead {
		padding: 5px 0px;
	}
}
