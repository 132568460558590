.referral-success {
	.pageDescription {
		color: #67696f;
		//font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
	}
}
@media only screen and (max-width: 768px) {
	.referral-success {
		padding: 0;
		.pageDescription {
			font-size: 24px;
		}
	}
}

@media screen and (max-width: 576px) {
	.referral-success {
		.pageDescription {
			font-size: 16px;
		}
	}
}
