.cookie-policy {
	background-color: #f4f4f5;
	//padding-top: 80px;
	.cookie-content {
		background-color: #ffffff;
		padding: 5%;
		border-radius: 32px;
		max-width: 800px;
		margin: 0 auto;
		font-size: 0.9em;

		#cookie-policy-description,
		#ot-sdk-cookie-policy p {
			text-align: justify;
		}
	}
}
