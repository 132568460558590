@use '../../../theme/colors';

.select-box {
	padding: 15px 20px;
	border: 1px solid colors.$ts-color-gray-50;
	border-radius: 0;

	&__container {
		margin-top: 25px;
	}

	.disabled & {
		border-color: colors.$ts-color-gray-30;
		background-color: transparent;
		color: colors.$ts-color-gray-50;
	}

	&__container.roundTheme {
		display: inline-block;
		margin-top: 0;
	}

	&__container.roundTheme & {
		width: auto;
		padding: 8px 35px 8px 16px;
		border-radius: 90px;
	}
}
