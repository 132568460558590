@use './theme/colors';
@use './theme/spacing';

@import './theme/fonts';
@import './theme/breakpoints';
@import './theme/mixins';

body {
	font-family: 'Inter';
	color: colors.$ts-color-gray-100;
}

a {
	cursor: pointer;
}

/* Scroll Bar Starts */
::-webkit-scrollbar {
	height: 6px;
	width: 6px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #5c4bb9;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #5c4bb9;
}

.App {
	background-color: #ffffff;
}

.bgimg {
	background: url('./assets/images/OnBoarding/loginbackground.png') no-repeat bottom right;
}
/* For Account Header */
.nextLogo {
	width: 250px;
	object-fit: cover;
}
.defaultLogo {
	width: 180px;
}
.navbar {
	padding: 0;
}

.logo_img-container {
	cursor: pointer;
	display: inline-block;
	margin: 0.5% 0;
}

.logo_img-container img {
	width: auto;
	max-width: 240px;
	max-height: 80px;
	//margin: 2%;
}

// .dalign {
// 	text-align: right;
// }

.pageLabel {
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 45px;
	letter-spacing: -1px;
}

.pageDescription {
	color: #67696f;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.2px;
}

.confirmBtn {
	color: #fff;
	background-color: #5c4bb9;
	border-color: #5c4bb9;
	height: 40px;
	padding: 0px 20px;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: -0.3px;

	&:hover {
		background-color: #342685;
		border-color: #342685;
	}

	&:focus {
		box-shadow: none !important;
		color: #fff;
		background-color: #5c4bb9;
		border-color: #5c4bb9;
	}

	&.disabled {
		color: #fff;
		background-color: #5c4bb9;
		border-color: #5c4bb9;
	}

	&:focus-visible {
		outline: 2px solid #000;
	}
}

.defaultBtn {
	color: #000000;
	background-color: #fff;
	border-color: #282a32;
	height: 40px;
	padding: 0px 20px;
	font-family: Inter;
	font-size: 17px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: -0.3px;

	&:hover {
		color: #000000;
		background-color: #fff;
		border-color: #282a32;
	}

	&:focus {
		box-shadow: none !important;
		color: #000000;
		background-color: #fff;
		border-color: #282a32;
	}

	&.disabled {
		color: #000000;
		background-color: #939498;
		border-color: #282a32;
	}
}

label.form-label {
	color: #000;
	font-family: 'Inter-MediumBold';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.1px;
}

.form-control:focus {
	background-color: #fff;
	border-color: #5c4bb9;
	outline: 0;
	box-shadow: none;
	/* box-shadow: 0 0 0 0.25rem #958ad1; */
}

.form-check-input:focus {
	border-color: #5c4bb9;
	box-shadow: 0 0 0 0.25rem #958ad1;
}

.form-check-input:checked {
	background-color: #5c4bb9;
	border-color: #5c4bb9;
}

.form-select:focus {
	background-color: #fff;
	border-color: #5c4bb9;
	outline: 0;
	box-shadow: none;
}

.already {
	color: #282a32;
	font-family: 'Inter-Medium';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.2px;
}

.signIn {
	color: #282a32;
	font-family: 'Inter';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.1px;
	text-decoration-line: underline;
}

.imt {
	max-height: 100vh;
}

.mt-25 {
	margin-top: 8rem;
}

.alignCenter {
	text-align: center;
}

.globalBg {
	background: url('./assets/images/OnBoarding/Logingraphic.png') no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center;
}

.bannerImg {
	background-image: url('./assets/images/Heroes.png');
	height: 300px;
}

.nextNav .nav-item .nav-link {
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 100% */
	letter-spacing: -0.2px;
	color: #282a32;
	//margin-left: 20px;
	&:hover {
		text-decoration: underline;
	}
}

/* Screen from 1399 */
@media only screen and (min-width: 1399px) {
	.pageLabel {
		margin-top: 8vh;
		/* margin-top: 25vh; */
	}
}

@media only screen and (min-width: 990px) {
	.navbar-nav ul {
		display: flex;
		flex-direction: row;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
}

/* Screen upto 991 */
@media only screen and (max-width: 991px) {
	.onBoardPadding {
		padding: 0px;
	}
}

@media (max-width: 768px) {
	.col-12 {
		padding: 0;
	}

	.logo_img-container img {
		width: auto;
		max-width: 305px;
		max-height: 80px;
	}
}

/* Media queries for screen less than 576 Starts */
@media only screen and (max-width: 576px) {
	.row {
		margin: 0;
	}

	.loginHeader {
		//padding: 10px 15px;
	}

	.contentArea {
		padding: 10px 15px;
	}

	.pageLabel {
		font-size: 20px;
	}

	.referralsContainer .leftPanel .title {
		font-size: 22px;
	}

	.logo_img-container {
		cursor: pointer;
		display: inline-block;
		margin: 0.5%;
	}

	.logo_img-container img {
		width: auto;
		max-width: 220px;
		max-height: 80px;
		//margin: 2%;
	}
}

/** Common **/
button.btn:active {
	box-shadow: none;
}
button.btn:focus {
	box-shadow: none;
}
.dropdown {
	position: inherit;
}
.template-dropdown .dropdown-menu {
	padding: 8px;
	min-width: 18rem;
}
span.dropdown-item {
	color: #67696f;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.1px;
}
span.dropdown-item:focus {
	background: #f1f3f9;
	border-radius: 5px;
}

span.dropdown-item:hover {
	background: #f1f3f9;
	border-radius: 5px;
}
/** Common **/

/** Form Styling : start **/
.was-validated .form-control {
	&:valid {
		background-image: none;
	}
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/** Form Styling : end **/

.vs-comp {
	margin-top: spacing.$vs-between-comp-sm;

	@include md {
		margin-top: spacing.$vs-between-comp-md;
	}
}

.remove-style {
	@include font-style(inherit, inherit, inherit, inherit);
	border: 0;
	padding: 0;
	background: transparent;
	text-align: left;
}

.snackbar__container {
	position: fixed;
	bottom: 4rem;
	right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	color: #323232;
	z-index: 10;
	margin: 16px;
	border-radius: 4px;
	min-width: 344px;
	max-width: 672px;
	box-shadow:
		0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14),
		0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	padding: 8px;
	animation: 300ms ease-out forwards slide-up;
	overflow: hidden;

	&.error {
		background: #ff3154;
		color: #fff;
	}

	&.success {
		background: #4bdd33;
		color: #fff;
	}

	&.warning {
		background: #ff8b10;
		color: #fff;
	}
}

.snackbar__label {
	line-height: 1.2rem;
	padding-left: 16px;
	padding-right: 8px;
	width: 100%;
	margin: 0;
	padding-top: 14px;
	padding-bottom: 14px;
}

.snackbar__dismiss {
	color: #323232;
	font-size: 120%;
	font-weight: bold;
	margin-left: 8px;
	margin-right: 8px;
	cursor: pointer;
	padding: 8px;
}

@keyframes slide-up {
	from {
		opacity: 0;
		transform: translateY(3rem);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}
