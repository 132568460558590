@use '../../../theme/colors';

.react-datepicker {
	&-wrapper {
		display: block;
	}

	&__container {
		margin-top: 25px;
	}

	&__input-container {
		input {
			padding: 15px 20px;
			width: 100%;
			border: 1px solid colors.$ts-color-gray-50;
			border-radius: 0;
		}
	}
}
