@use '../../theme/spacing';
@use '../../theme/colors';

@import '../../styles/tabs.scss';

.client-team {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.teamsTab {
		padding: 8px;
	}
}

@media screen and (min-width: 577px) and (max-width: 767px) {
	.teamsTab {
		padding: 10px;
	}
}
