/* For Account Header */
.dropdown-toggle::after {
	content: url('../../../assets/images/DownArrowWhite.png');
	border: none;
	width: 10px;
	height: auto;
	margin-left: 0.4rem;
	vertical-align: middle;
}

.dropdown-toggle.show::after {
	content: url('../../../assets/images/UpArrowWhite.png');
	border: none;
	width: 10px;
	height: auto;
	margin-left: 0.4rem;
	vertical-align: middle;
}

.dropdown-toggle:focus-visible {
	outline: 1.5px solid #fff;
}

.accountHeader {
	background: #000000;
}

.accountHeader img {
	vertical-align: middle;
	padding: 3px 5px 8px 5px;
}

.accountHeader .languageSwitch button {
	color: #fff;
	background-color: #000000;
	border: none;
}

.accountHeader .languageSwitch span.dropdown-item {
	color: #67696f;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.1px;
	padding: 4%;
	cursor: pointer;
}

.accountHeader .languageSwitch span.dropdown-item:focus {
	background: #f1f3f9;
	border-radius: 5px;
}

.accountHeader .languageSwitch span.dropdown-item:hover {
	background: #f1f3f9;
	border-radius: 5px;
}

.accountHeader .languageSwitch button:focus {
	box-shadow: none;
}

.accountHeader .languageSwitch .dropdown-menu {
	padding: 8px;
	margin-top: 10px;
}

.myAccountBtn {
	background: no-repeat;
	color: #fff;
	border: none;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 100% */
	letter-spacing: -0.2px;
	margin-top: 2px;
}

.notificationBtn {
	background: no-repeat;
	color: #fff;
	border: none;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	/* 100% */
	letter-spacing: -0.2px;
	margin-top: 2px;
}
.myAccountBtn:hover,
.notificationBtn:hover {
	text-decoration: underline;
}
.redDot {
	height: 10px;
	width: 10px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	margin-left: 10px;
}
.headerLogOutBtn {
	background: no-repeat;
	color: #fff;
	border: none;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: -0.2px;
	margin-top: 2px;
}
.headerLogOutBtn:hover {
	text-decoration: underline;
}
.headerLogOutBtn img {
	width: 25px;
}
// @media screen and(min-width:577px) and (max-width:768px) {
// 	.accountHeaderNav {
// 		margin-left: 0.5rem;
// 	}
// }
// @media screen and(max-width:576px){
// 	.accountHeaderNav{
// 		margin-left:0.5rem;
// 	}
// }
/* For Account Header */
