@use '../../../theme/colors';
@import '../../../theme/mixins';
@import '../../../theme/breakpoints';

.resource-tile {
	&__title {
		@include font-style(19px, 700, 24px, -0.1px);
		cursor: pointer;

		.resource-tile__first-tile & {
			@include font-style(40px, 700, 45px, -1px);
		}
	}

	&__description {
		@include font-style(14px, 400, 18px, -0.1px);
		color: colors.$ts-color-gray-75;

		@include md {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.resource-tile__first-tile & {
			@include font-style(16px, 400, 24px, -0.2px);
			display: block;
			overflow: visible;
		}
	}

	&__type {
		@include font-style(12px, 400, 17px, 0px);
		margin-top: 12px;
		color: colors.$ts-color-gray-75;
		text-transform: uppercase;
	}

	&__image img {
		//width: 100%;
		// height: 100%;
		object-fit: cover;
		border-radius: 10px;
	}

	&__first-tile {
		margin-bottom: 64px;
	}

	&__desc {
		background-color: colors.$ts-color-blue-10;
		padding: 32px !important;
		border-radius: 10px;

		@include md {
			padding: 44px 48px !important;
		}
	}

	&__view {
		$p: &;

		background-color: colors.$white;
		position: absolute;
		right: calc(var(--bs-gutter-x) * 0.01);
		bottom: 0;
		padding: 12px 18px;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		border-top-left-radius: 10px;
		cursor: pointer;

		&::before,
		&::after {
			content: '';
			position: absolute;
			background-color: transparent;
			width: 8px;
			box-shadow: 0 -8px 0 0 #fff;
		}

		&::before {
			left: -8px;
			bottom: 0px;
			height: 50px;
			border-top-left-radius: 8px;
			transform: rotate(180deg);
		}

		&::after {
			right: 7px;
			top: -15px;
			height: 22px;
			border-top-right-radius: 8px;
			transform: rotate(90deg);
		}

		&-text {
			display: none;
		}

		&:hover {
			#{$p}-sign {
				display: none;
			}

			#{$p}-text {
				display: inline-block;
			}
		}
	}
}
