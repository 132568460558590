@use '../../theme/colors';
.groupd-filter-container {
	position: relative;
	padding-bottom: 39.75%;
	height: 0;
	overflow: hidden;
}

.groupd-filter-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.groupd-keystat-container {
	position: relative;
	padding-bottom: 32.75%;
	height: 0;
	overflow: hidden;
}

.groupd-keystat-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.groupd-candidates-container {
	position: relative;
	padding-bottom: 137.75%;
	height: 0;
	overflow: hidden;
}

.groupd-candidates-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.groupd-location-container {
	position: relative;
	padding-bottom: 70.75%;
	height: 0;
	overflow: hidden;
}

.groupd-location-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.dashboard__container .tabs .tab-content {
	padding-top: 0px;
	//margin-left:-10px
}

.groupbContainer {
	padding: 50px 60px 50px 60px;
	border-radius: 10px;
	background: colors.$ts-color-blue-10;
	margin-top: 10px;
	margin-bottom: 10px;
	//width: 101%;

	& .title {
		color: colors.$ts-color-gray-100;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: 45px;
		letter-spacing: -1px;
		padding: 0;
		margin-bottom: 15px;
	}

	& .desc {
		color: colors.$ts-color-gray-75;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
		border-bottom: 1px solid;
		padding: 0px 0px 20px 0px;
	}

	& .jumpTo {
		color: colors.$ts-color-gray-75;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	& .navigationBlock {
	}

	& .btn-outline-secondary {
		color: colors.$black;
		border: none;
		font-size: 17px;
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: -0.3px;
		padding: 0px;
		margin-right: 10px;
	}

	& .btn-outline-secondary:hover {
		background: none;
	}

	& .filterBtn {
		color: colors.$black;
		border: 1px solid #282a32;
		font-size: 17px;
		font-weight: 600;
		letter-spacing: -0.3px;
		background: transparent;

		img {
			padding: 5px;
		}
	}
}
@media only screen and (max-width: 768px) {
	.groupbContainer {
		padding: 50px 30px 50px 30px;
	}
}
@media only screen and (max-width: 576px) {
	.groupbContainer {
		padding: 50px 20px 50px 20px;
	}
}

// .groupbsearchContainer {
// 	justify-content: flex-end;
// 	gap: 1%;
// 	margin-bottom: 5px;
// 	padding-right: 2%;

// 	& .btn-secondary {
// 		border: 1px solid colors.$black;
// 		border-radius: 0;
// 	}

// 	& .filterSearchBtn {
// 		border: 1px solid colors.$ts-color-violet-75;
// 		background-color: colors.$ts-color-violet-75;
// 		color: colors.$white;
// 		font-size: 16px;
// 		font-style: normal;
// 		font-weight: 500;
// 		line-height: 16px;
// 		letter-spacing: -0.3px;
// 		padding: 12px 12px;
// 	}

// 	& .filterResetBtn {
// 		border: 1px solid colors.$ts-color-gray-100;
// 		background-color: colors.$white;
// 		color: colors.$black;
// 		font-size: 16px;
// 		font-style: normal;
// 		font-weight: 500;
// 		line-height: 16px;
// 		letter-spacing: -0.3px;
// 		padding: 12px 12px;
// 	}
// }

// @media only screen and (max-width: 768px) {
// 	.groupbContainer {
// 		padding: 20px;
// 		& .title {
// 			font-size: 27px;
// 			font-style: normal;
// 			font-weight: 700;
// 			line-height: 34px;
// 			letter-spacing: -0.5px;
// 		}
// 	}
// 	.reportClassD_filter {
// 		height: 1457px;
// 	}

// 	.reportClassD_key {
// 		height: 2034px;
// 	}

// 	.reportClassD_satisf {
// 		height: 3185px;
// 	}

// 	.reportClassD_outcome {
// 		height: 1539px;
// 	}
// }

// @media only screen and (max-width: 576px) {
// 	.reportClassD_filter {
// 		height: 141vh;
// 	}

// 	.reportClassD_key {
// 		height: 194vh;
// 	}

// 	.reportClassD_satisf {
// 		height: 308vh;
// 	}

// 	.reportClassD_outcome {
// 		height: 151vh;
// 	}
// }

// @media only screen and (max-width: 430px) {
// 	.reportClassD_filter {
// 		height: 812px;
// 	}

// 	.reportClassD_key {
// 		height: 1111px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1747px;
// 	}

// 	.reportClassD_outcome {
// 		height: 870px;
// 	}
// }

// @media only screen and (max-width: 425px) {
// 	.reportClassD_filter {
// 		height: 810px;
// 	}

// 	.reportClassD_key {
// 		height: 1113px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1670px;
// 	}

// 	.reportClassD_outcome {
// 		height: 843px;
// 	}
// }

// @media only screen and (max-width: 414px) {
// 	.reportClassD_filter {
// 		height: 783px;
// 	}

// 	.reportClassD_key {
// 		height: 1070px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1678px;
// 	}

// 	.reportClassD_outcome {
// 		height: 839px;
// 	}
// }

// @media only screen and (max-width: 390px) {
// 	.reportClassD_filter {
// 		height: 740px;
// 	}

// 	.reportClassD_key {
// 		height: 1008px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1576px;
// 	}

// 	.reportClassD_outcome {
// 		height: 793px;
// 	}
// }

// @media only screen and (max-width: 376px) {
// 	.reportClassD_filter {
// 		height: 714px;
// 	}

// 	.reportClassD_key {
// 		height: 970px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1513px;
// 	}

// 	.reportClassD_outcome {
// 		height: 764px;
// 	}
// }

// @media only screen and (max-width: 360px) {
// 	.reportClassD_filter {
// 		height: 686px;
// 	}

// 	.reportClassD_key {
// 		height: 931px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1448px;
// 	}

// 	.reportClassD_outcome {
// 		height: 764px;
// 	}
// }

// @media only screen and (max-width: 320px) {
// 	.reportClassD_filter {
// 		height: 569px;
// 	}

// 	.reportClassD_key {
// 		height: 761px;
// 	}

// 	.reportClassD_satisf {
// 		height: 1107px;
// 	}

// 	.reportClassD_outcome {
// 		height: 583px;
// 	}
// }
