@use '../../theme/colors';

@import '../../theme/breakpoints';

.home {
	&__reports {
		background-color: colors.$ts-color-blue-5;
		background-image: linear-gradient(
			to right,
			colors.$ts-color-blue-5 0%,
			colors.$ts-color-blue-5 70%,
			colors.$ts-color-blue-10 70%,
			colors.$ts-color-blue-10 100%
		);

		// @include md {
		// 	background-image: linear-gradient(
		// 		to right,
		// 		colors.$ts-color-blue-5 0%,
		// 		colors.$ts-color-blue-5 70%,
		// 		colors.$ts-color-blue-10 70%,
		// 		colors.$ts-color-blue-10 100%
		// 	);
		// }
	}

	&__referrals-section {
		background-color: colors.$ts-color-blue-10;
		border-radius: 40px 0px 0px 0px;
		// @include md {
		// 	border-radius: 40px 0px 0px 0px;
		// }
	}
}

@media screen and (max-width: 991px) {
	.home__reports {
		background-image: none;
	}
	.home__referrals-section {
		border-radius: 0px 0px 0px 0px;
		padding: 0;
	}
}
