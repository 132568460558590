@use '../../../theme/colors';
@import '../../../theme/breakpoints';

.my-team {
	&__deleted-user {
		&.ag-row-odd,
		&.ag-row-even,
		&.ag-row-selected &.ag-row-hover,
		&.ag-row-focus {
			background-color: colors.$ts-color-gray-25 !important;
			color: colors.$ts-color-gray-50;
			font-style: italic;
			text-shadow: 1px 1px colors.$ts-color-gray-10;

			&::before {
				display: none;
			}
		}
	}
}
