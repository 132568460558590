@use '../../theme/colors';

.my-account {
	&__summary {
		background-color: colors.$ts-color-blue-5;
		padding: 55px 0px 40px 0px;
		//padding: 4rem 0.25rem 4rem 0.25rem;

		&-desc {
			color: colors.$ts-color-gray-75;
		}
	}

	hr {
		margin: 32px 0;
	}

	&__settings,
	&__reset-pass {
		margin-top: 60px;
	}
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
	.my-account {
		&__summary {
			padding: 55px 0px 40px 0px;
		}
	}
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
	.my-account {
		&__summary {
			padding: 2.5rem 0.25rem 2.5rem 0.25rem;
		}
	}
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
	.my-account {
		&__summary {
			padding: 2.5rem 1rem 2.5rem 1rem;
		}
	}
}

@media only screen and (max-width: 576px) {
	.my-account {
		&__summary {
			padding: 2.5rem 0rem 2.5rem 0rem;
		}
	}
}

// @media only screen and (max-width: 425px) {
// }

// @media only screen and (max-width: 375px) {
// }

// @media only screen and (max-width: 320px) {

// }
