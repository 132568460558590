@use '../../theme/spacing';
@use '../../theme/colors';

@import '../../styles/tabs.scss';

.dashboard {
	&__container {
		margin-top: spacing.$vs-between-comp-md;

		.searchContainer {
			justify-content: flex-end;
			gap: 1%;
			margin-bottom: 5px;
			padding-right: 2%;

			& .btn-secondary {
				border: 1px solid colors.$black;
				border-radius: 0;
			}

			& .filterSearchBtn {
				border: 1px solid colors.$ts-color-violet-75;
				background-color: colors.$ts-color-violet-75;
				color: colors.$white;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px;
				letter-spacing: -0.3px;
				padding: 12px 12px;

				&:focus-visible {
					outline: 2px solid #000;
				}
			}

			& .filterResetBtn {
				border: 1px solid colors.$ts-color-gray-100;
				background-color: colors.$white;
				color: colors.$black;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px;
				letter-spacing: -0.3px;
				padding: 12px 12px;

				&:focus-visible {
					outline: 2px solid #000;
				}
			}
		}
	}
	.dasboardTab {
		padding-top: 0;
	}
}

@media only screen and (max-width: 768px) {
	.dashboard__container {
		.searchContainer {
			& .filterSearchBtn {
				padding: 10px 10px;
			}

			& .filterResetBtn {
				padding: 10px 10px;
			}
		}
	}
}

@media only screen and (max-width: 576px) {
	.dashboard__container {
		margin-top: 35px;
		.searchContainer {
			margin-top: 10px;
			& .filterSearchBtn {
				padding: 8px 8px;
			}

			& .filterResetBtn {
				padding: 8px 8px;
			}
		}
	}
}

@media only screen and (max-width: 375px) {
	.dashboard__container {
		margin-top: 35px;

		.nav.nav-tabs {
			justify-content: space-between;
		}

		.tabs .nav-item {
			margin: 0;
		}
	}
}
