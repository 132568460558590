@use '../../../theme/colors';

.input-box {
	padding: 15px 20px;
	border: 1px solid colors.$ts-color-gray-50;
	border-radius: 0;

	&__container {
		margin-top: 25px;
	}

	.disabled & {
		border-color: colors.$ts-color-gray-30;
		background-color: transparent;
		color: colors.$ts-color-gray-50;
	}
}
