@use '../../theme//colors';

@import '../../theme/mixins';

.report-view {
	margin-top: 50px;

	&__cancel-btn {
		height: 40px;
		padding: 0px 24px;
		border-radius: 100px;
		border: 1px solid colors.$ts-color-gray-100;
		background-color: transparent;
		color: colors.$black;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: -0.3px;

		img.closeIcon {
			padding-right: 5px;
			vertical-align: unset;
		}

		&:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}

		&:active {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}

		&:active:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}

		&:hover {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}
	}

	&__uploadedBy {
		display: inline-flex;
		padding: 10px 19px 10px 10px;
		align-items: center;
		gap: 10px;
		border-radius: 90px;
		background: colors.$ts-color-blue-10;
		width: auto;
		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
		}
		.byText {
			color: colors.$ts-color-gray-100;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.2px;
		}
		.byName {
			color: colors.$ts-color-gray-100;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -0.1px;
		}
	}

	&__downloadReportBtn {
		border-radius: 100px;
		background: colors.$ts-color-violet-75;
		border: 1px solid colors.$ts-color-violet-75;
		color: colors.$white;
		font-size: 17px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.3px;
		width: 100%;

		img {
			padding: 5px 5px 13px 5px;
			vertical-align: middle;
		}

		&:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-violet-75;
			background-color: colors.$ts-color-violet-75;
			color: colors.$white;
		}

		&:active {
			box-shadow: none;
			border: 1px solid colors.$ts-color-violet-75;
			background-color: colors.$ts-color-violet-75;
			color: colors.$white;
		}

		&:active:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-violet-75;
			background-color: colors.$ts-color-violet-75;
			color: colors.$white;
		}

		&:hover {
			box-shadow: none;
			border: 1px solid colors.$ts-color-violet-75;
			background-color: colors.$ts-color-violet-75;
			color: colors.$white;
		}

		span.fileData {
			@include font-style(16px, 400, 36px, -0.2px);
			float: right;
		}
	}

	& .docx-wrapper {
		background: gray;
		padding: 30px;
		padding-bottom: 0px;
		display: flex;
		flex-flow: initial;
		align-items: initial;

		& .docx {
			padding: 20px !important;
		}
	}

	& .docx-wrapper {
		background: gray;
		padding: 30px;
		padding-bottom: 0px;
		display: flex;
		flex-flow: initial;
		align-items: initial;

		& .docx {
			padding: 20px !important;
		}
	}

	& .pdfViewer {
		min-height: 450px;
		max-height: 95%;
		height: 1050px;
	}

	& .noPreview {
		height: 20%;
		width: 100%;
		background: rgb(196, 196, 196);
		color: colors.$ts-color-gray-100;
		text-align: center;
		padding: 10%;
	}

	@media only screen and (max-width: 768px) {
		.report-view__downloadReportBtn {
			@include font-style(15px, 600, 32px, -0.3px);
			width: 100%;
		}
		.report-view__downloadReportBtn span.fileData {
			margin-left: 32px;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 34px;
			letter-spacing: -0.2px;
		}

		.restrict-card {
			height: 500px;
			overflow-y: overlay;
		}

		& .pdfViewer {
			min-height: 450px;
			max-height: 95%;
			height: 1050px;
		}
	}
}

.resource-view {
	&__footer-view {
		img {
			width: 100%;
		}
	}

	&__banner {
		margin: 30px 0;

		img {
			width: 100%;
			border-radius: 20px;
		}
	}

	&__description {
		margin-top: 30px;

		img {
			border-radius: 20px;
		}
	}
}
