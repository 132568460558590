#header-bar,
#pdf-controls,
#pdf-download,
#pdf-toggle-pagination {
	display: none;
}

#react-doc-viewer {
	border: 1px solid#efefef;
}

.react-pdf__Document {
	background-color: #efefef;
	border: 1px solid#efefef;
}

.textLayer {
}
