@use '../../../theme/colors';

@import '../../../theme/breakpoints';

.sort-search {
	padding: 1.25rem;
	&__search {
		border-radius: 90px;

		@include md {
			border: 1px solid colors.$ts-color-gray-30;
			padding: 10px 10px 10px 30px;
		}

		.button {
			padding-top: 10px;
			padding-bottom: 10px;

			@include sm {
				width: 100%;
			}
		}
	}

	&__search-box {
		background: url('../../../assets/images/icon/search.png') no-repeat left center;
		padding-left: 22px;
		width: 100%;
		border: none;

		@include sm {
			background-position: 20px center;
			padding: 15px 10px 15px 45px;
			border: 1px solid colors.$ts-color-gray-30;
			border-radius: 90px;
		}
	}

	&__sort {
		@include sm {
			.select-box__container {
				width: 47%;

				select {
					width: 100% !important;
				}
			}
		}
	}
}

@media screen and (min-width: 769px) and (max-width: 991px) {
	.sort-search__sort .select-box__container {
		width: 49%;
		.select-box {
			padding: 8px 35px 8px 10px;
		}
	}
	select {
		width: 100% !important;
	}
}

@media screen and (max-width: 576px) {
	.sort-search__sort .select-box__container {
		.select-box {
			padding: 8px 35px 8px 12px;
		}
	}
}
