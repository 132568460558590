@media screen and (min-width: 768px) and (max-width: 991px) {
	.myaccount_personal {
		padding: 0.25rem;
		.row {
			margin: 0;
		}
	}

	.myaccount_password {
		padding: 0.25rem;
	}
}
@media screen and (min-width: 576px) and (max-width: 767px) {
	.myaccount_personal {
		padding: 1rem;
	}
	.myaccount_password {
		padding: 0.25rem;
	}
}
@media screen and (max-width: 575px) {
	.myaccount_personal {
		padding: 0;
	}

	.myaccount_password {
		padding: 0;
	}
}
