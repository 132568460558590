/* Media queries for screen less than 576 Starts */
@media screen and (max-width: 991px) {
	.navbar-expand-md .navbar-toggler {
		display: block;
	}
	.navbar-light .navbar-toggler {
		border: none;
	}

	.navbar-toggler:focus {
		box-shadow: none;
	}

	.navbar-collapse {
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 35px;
		padding-right: 15px;
		padding-bottom: 15px;
		width: 70%;
		height: 100%;
		z-index: 9999;
	}

	.navbar-collapse.collapsing {
		height: auto;
		-webkit-transition: left 0.3s ease;
		-o-transition: left 0.3s ease;
		-moz-transition: left 0.3s ease;
		transition: left 0.3s ease;
		left: -100%;
		z-index: 9999;
	}

	.navbar-collapse.show {
		left: -10%;
		-webkit-transition: left 0.3s ease-in;
		-o-transition: left 0.3s ease-in;
		-moz-transition: left 0.3s ease-in;
		transition: left 0.3s ease-in;
		z-index: 9999;
	}

	.navbar-nav {
		background: #000000;
		padding: 4% 4% 4% 6%;

		& ul {
			list-style-type: none;
			//padding-left: 0.5rem;
			background-color: transparent;
		}
	}

	.nextNav .nav-item .nav-link {
		color: #fff;
		font-size: 20px;
		margin-bottom: 2%;
	}

	.navbar-light .navbar-nav .nav-link.active,
	.navbar-light .navbar-nav .show > .nav-link {
		color: #fff;
	}

	.navClose {
		width: 40%;
		border: 2px solid #fff;
		color: #fff;
		background: #000;
	}

	.accountHeader .languageSwitch .dropdown-menu li {
		border-bottom: 1px solid #282a32;
	}

	.accountHeader .languageSwitch span.dropdown-item {
		color: #fff;
		font-size: 16px;
		padding: 2% 4% 4% 1%;
	}
	.accountHeader .languageSwitch span.dropdown-item:hover {
		background: none;
	}

	.myAccountBtn,
	.notificationBtn,
	.headerLogOutBtn {
		font-size: 16px;
	}

	.accountHeader .languageSwitch button {
		font-size: 16px;
	}
}

@media screen and (max-width: 768px) {
	.navbar-collapse {
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 35px;
		padding-right: 15px;
		padding-bottom: 15px;
		width: 70%;
		height: 100%;
		z-index: 9999;
	}
	.navbar-collapse.collapsing {
		z-index: 9999;
	}
	.navbar-collapse.show {
		z-index: 9999;
	}
	.navbar-nav {
		& ul {
			//padding-left: 0.5rem;
		}
	}
}

@media screen and (max-width: 576px) {
	.navbar-collapse {
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 15px;
		width: 100%;
		z-index: 9999;
	}
	.navbar-collapse.collapsing {
		z-index: 9999;
	}

	.navbar-collapse.show {
		z-index: 9999;
	}
	.accountHeader .languageSwitch span.dropdown-item {
		padding-left: 0px;
	}
	.navbar-nav {
		& ul {
			//padding-left: 1.5rem;
		}
	}
}
