@use '../../../theme/colors';
.overlay {
	background: colors.$white;
	color: colors.$ts-color-blue-10;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 5000;
	top: 0;
	left: 0;
	float: left;
	text-align: center;
	padding-top: 25%;
	opacity: 0.8;
	& .spinner-border {
		color: colors.$ts-color-violet-100;
	}
}
