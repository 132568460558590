@use '../../theme//colors';

.create-referral {
	&__cancel-btn {
		height: 40px;
		padding: 0px 24px;
		border-radius: 100px;
		border: 1px solid colors.$ts-color-gray-100;
		background-color: transparent;
		color: colors.$ts-color-gray-100;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: -0.3px;

		img.closeIcon {
			padding-right: 5px;
		}

		&:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}
		&:active {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}
		&:active:focus {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}
		&:hover {
			box-shadow: none;
			border: 1px solid colors.$ts-color-gray-100;
			background-color: transparent;
			color: colors.$ts-color-gray-100;
		}

		&:focus-visible {
			outline: 2px solid #000;
		}
	}

	&__left {
		position: relative;
		.createReferralContent {
			position: absolute;
			top: 5%;
			left: 5%;
			width: 60%;
			.pageTitle {
				color: colors.$ts-color-gray-100;
				font-size: 65px;
				font-weight: 700;
				line-height: 85px;
				letter-spacing: -3px;
			}

			.pageDescription {
				color: colors.$ts-color-gray-100;
				font-size: 20px;
				font-weight: 400;
				line-height: 28px;
			}

			.pageDownloadContent {
				color: colors.$ts-color-gray-100;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.2px;

				.downloadLink {
					color: colors.$ts-color-gray-100;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					letter-spacing: -0.1px;
					text-decoration-line: underline;
					cursor: pointer;
				}
			}
		}
	}

	&__right {
		.selectStatement {
			color: colors.$ts-color-gray-100;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -0.1px;
		}

		.checkBoxSection {
			.checkBoxLable {
				display: inline;
				align-items: center;
				cursor: pointer;
				font-weight: 500;

				.cbInput {
					margin-right: 10px;
				}
			}
			.assistanceContent {
				color: colors.$ts-color-gray-100;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.2px;

				.assistanceLink {
					color: colors.$ts-color-gray-100;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					letter-spacing: -0.1px;
					text-decoration-line: underline;
				}
			}
		}
	}

	&__uploaded-files {
		border-radius: 10px;
		background: colors.$ts-color-blue-10;

		.listOfFiles {
			border-radius: 5px;
			border-bottom: colors.$ts-color-gray-25;
			background: colors.$white;
			list-style: none;
			padding: 10px;

			img.removeAttachment {
				cursor: pointer;
				margin-left: 8px;
				vertical-align: initial;
			}
		}
	}

	@media only screen and (max-width: 991px) {
		&__left .createReferralContent {
			position: initial;
			width: 100%;
		}
		.create-referral__right .checkBoxSection .checkBoxLable .cbInput {
			margin-bottom: 0px;
		}
	}
}
