@use '../../../theme/colors';

@import '../../../theme/mixins';
@import '../../../theme/breakpoints';

.your-referrals {
	padding: 40px 20px;

	@include md {
		padding: 55px 0 55px 55px;
	}

	@include md {
		//padding: 0.75rem;
	}

	.refListSection {
		border-bottom: 2px solid #d6dae7;
		margin-bottom: 20px;
	}
	&__refTitle {
		@include font-style(27px, 700, 45px, -1px);
		color: colors.$black;
	}
	&__refName {
		color: colors.$ts-color-gray-100;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
		display: flex;
	}
	&__refDate {
		color: colors.$ts-color-gray-75;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 17px;
		display: flex;
	}
	&__status {
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 12px;
		letter-spacing: 0.2px;
		color: colors.$black;
		padding: 8px 8px;
		margin: 5px 0px 1px 0px;
		border-radius: 50px;
		display: inline-flex;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.approved {
		background: colors.$approved-green;
	}
	.pending {
		background: colors.$pending-orange;
	}
	.rejected {
		background: colors.$rejected-pink;
	}
	.inprogress {
		background: rgba(255, 122, 18, 0.6);
	}
	.noRecords {
		color: colors.$black;
		font-size: 14px;
	}
}
