@use '../../theme//colors';

.program-descriptor {
	margin-top: 50px;

	&__title {
	}

	&__description {
		color: #67696f;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
	}

	.table {
		font-family: Inter !important;
		font-size: 10px !important;
		letter-spacing: 0.2px;
		min-height: 50px;
		max-height: 500px;
		overflow-y: scroll;
		grid-template-columns: 88% 12%;
	}
	.table.noscroll {
		overflow-y: hidden;
	}
	// .css-811rls-HEADER_CELL_CONTAINER_STYLE-HeaderCell>div{

	thead tr th {
		border-bottom: 1px solid #dde2eb !important;
		background: #f1f3f9;
	}
	thead tr th > div {
		font-family: Inter;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0.2px;
		text-transform: uppercase;
		color: colors.$ts-color-gray-75;
		padding: 0.5rem 0.5rem 0.5rem 0.25rem;
	}
	tbody tr td > div {
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.2px;
		color: colors.$black;
		padding: 0.5rem 0.5rem 0.5rem 0.25rem;
		margin-left: 4px;
	}
	tbody tr.row-tree-clickable td {
		background-color: #f8f9fc;
		border-bottom: 1px solid #dde2eb !important;
		//padding: 0.25rem 0.25rem;
	}
	tbody tr.row-tree-leaf td {
		background-color: colors.$white;
		border-bottom: 1px solid #dde2eb !important;
		//padding: 0.25rem 0.25rem;
	}
	.viewlink {
		font-family: Inter;
		text-align: left;
		font-size: 15px;
		font-weight: 500;
		cursor: pointer;
	}
	img.fileIcon {
		width: 100%;
		//height: 18px;
		height: 18px;
	}
	img.downArrow {
		width: 100%;
		//height: 6px;
		height: 18px;
	}
	img.rightArrow {
		width: 100%;
		height: 18px;
		//height: 6px;
		//transform: rotate(270deg);
	}

	@media only screen and (max-width: 576px) {
		.table {
			grid-template-columns: 60% 40%;
		}
	}
}
