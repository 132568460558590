@use '../../../theme/colors';

@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.schedule-meeting {
	background-image: linear-gradient(135deg, rgb(166, 235, 140, 0.5) 60%, rgb(86, 189, 237, 0.5));
	padding: 32px;
	border-radius: 10px;

	& button {
		margin-top: 10px;
	}
}

.meeting-form {
	&__buttons {
		margin-top: 25px;
	}

	@include sm {
		& .button {
			width: 100%;
		}
	}
}

.meeting-confirmation {
	& button {
		width: 100%;
	}

	@include md {
		padding: 30px 40px;

		& button {
			width: 70%;
		}
	}
}
