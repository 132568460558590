@use '../../../theme/colors';

.text-area {
	padding: 15px 20px;
	border: 1px solid colors.$ts-color-gray-50;
	border-radius: 0;

	&__container {
		margin-top: 25px;
	}
}
