@import '../../../theme/breakpoints';

.recent-reports {
	padding: 40px 0;

	@include md {
		//padding: 55px 55px 55px 0;
		padding: 0.75rem;
	}

	.report-list {
		margin-top: 0;
		margin-bottom: 20px;
	}
}
