@use '../../../../theme/colors';
.ExcelTable2007 {
	border: 1px solid #b0cbef;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
	height: 500px;
	color: colors.$ts-color-gray-100;
	font-family: 'Inter-Regular';
}

.ExcelTable2007 TH {
	background-image: url('../../../../assets/images/excel-2007-header-bg.gif');
	background-repeat: repeat-x;
	font-weight: bold;
	font-size: 14px;
	font-family: 'Inter-Regular';
	border: 1px solid #9eb6ce;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
	color: colors.$ts-color-gray-100;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #d0d7e5;
	border-width: 0px 1px 1px 0px;
	color: colors.$ts-color-gray-100;
	font-family: 'Inter-Regular';
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
	font-family: 'Inter-Regular';
}

.ExcelTable2007 TD.heading {
	background-color: #e4ecf7;
	text-align: center;
	border: 1px solid #9eb6ce;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
	font-family: 'Inter-Regular';
}

.ExcelTable2007 TH.heading {
	background-image: url('../../../../assets/images/excel-2007-header-left.gif');
	background-repeat: none;
}

TH {
	text-align: center;
}

.restrict-card {
	height: 700px;
	overflow-y: overlay;
}
