@use '../../../theme/colors';
.pagination {
	margin-top: 18px;
	.page-link {
		position: relative;
		display: block;
		color: colors.$black;
		border: 1px solid colors.$white;
		margin: 0px 2px 0px 2px;
	}

	.page-item.active .page-link {
		z-index: 3;
		color: colors.$white;
		background-color: colors.$black;
		border-color: colors.$black;
		border-radius: 2px;
	}

	.page-link:hover {
		z-index: 2;
		color: colors.$black;
		background-color: colors.$white;
		border: 1px solid colors.$black;
		border-radius: 2px;
	}

	.page-item.disabled .page-link {
		border: none;
		display: none;
	}

	.page-link:focus-visible {
		outline: 2px solid #000;
	}

	.page-link:focus {
		box-shadow: none;
		background-color: colors.$white;
	}

	.rightArrow {
		background-image: url('../../../assets/images/RightArrow.png');
	}
}
