$white: #ffffff;
$black: #000000;
$ts-color-blue-5: #f8f9fc;
$ts-color-blue-10: #f1f3f9;
$ts-color-blue-25: #56bded;
$ts-color-green-25: #a6eb8c;
$ts-color-violet-75: #5c4bb9;
$ts-color-violet-100: #4f4296;
$ts-color-gray-10: #f4f6f7;
$ts-color-gray-25: #efefef;
$ts-color-gray-30: #dedede;
$ts-color-gray-50: #939498;
$ts-color-gray-75: #67696f;
$ts-color-gray-100: #282a32;
$text-secondary: #67696f;
$ts-color-red-25: #fc5c7d;
$ts-color-red-50: #e4415f;
$ts-color-red-10: #fcecef;
$ts-color-gray-25: #efefef;
$banner-color-green: #acdcc4;
$approved-green: #a6eb8c;
$pending-orange: #ff7a12;
$rejected-pink: #fc5c7d;
