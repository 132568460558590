.loginHeader {
	padding: 0;
	.nextLogo {
		width: 80px;
	}
	.dalign {
		text-align: right;
	}
	.langDrop {
		color: #000000;
		border-color: #282a32;
		background-color: #ffffff;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.2px;
	}
	.btn-secondary:hover {
		box-shadow: none;
	}
	:focus {
		box-shadow: none;
	}

	.dropdown-toggle::after {
		content: url('../../assets/images/DownArrow.png');
		border: none;
		width: 10px;
		height: auto;
		margin-left: 0.4rem;
		vertical-align: baseline;
	}
	.dropdown-toggle.show::after {
		content: url('../../assets/images/UpArrow.png');
		border: none;
		width: 10px;
		height: auto;
		margin-left: 0.4rem;
		vertical-align: baseline;
	}

	.flag {
		width: 1.3rem;
		vertical-align: sub;
		margin-right: 5px;
	}

	.dropdown-item:active {
		background-color: #e9ecef;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.1px;
	}

	span.dropdown-item:active {
		background-color: #e9ecef;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.1px;
	}
	.logbackbtn {
		color: #000000;
		border-color: #282a32;
		background-color: #ffffff;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.2px;
	}
}
