@use '../../../theme/colors';

.report-list {
	margin-top: 50px;

	&__reportTitle {
	}

	&__reportDescription {
		color: #67696f;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
	}

	&__viewText {
		text-align: left;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
	}

	&__viewArrow {
		vertical-align: text-bottom;
		margin-left: 8px;
	}
	.gridStatus {
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px;
		color: colors.$black;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 22px;
		width: auto;
		padding: 5px 5px;
		margin-top: 12px;
		border-radius: 2px;
		background: colors.$ts-color-gray-25;
	}
}
