// .policy-header {
// 	position: relative;
// 	padding: 15px 20px;
// 	.logo-button {
// 		margin: auto;
// 		display: block;
// 		border: 0;
// 	}
// 	.back-button {
// 		position: absolute;
// 		border: none !important;
// 		background: transparent !important;

// 		.backtext {
// 			font-size: 16px;
// 			margin-left: 5px;
// 		}
// 	}
// 	.back-button:hover {
// 		color: #75d1fc;
// 	}
// }

.policy-header {
	padding: 15px 20px;
	.logo-button {
		margin: auto;
		display: block;
		border: 0;
	}
}
