.twoColumnTemplateAfterOnboard {
	.leftimg {
		height: 100vh;
		width: 80%;
	}
}
@media (max-width: 768px) {
	.twoColumnTemplateAfterOnboard {
		padding: 0;
	}
}
