@use '../../../theme/colors';

.errorBox {
	padding: 24px;
	border-radius: 10px;
	border: 1px solid colors.$ts-color-red-50;
	background: colors.$ts-color-red-10;

	& .errorTitle {
		color: colors.$ts-color-red-50;
		font-family: 'Inter-Medium';
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.1px;
	}

	& .errorDesc {
		color: colors.$ts-color-gray-75;
		font-family: Inter-Medium;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.2px;
	}

	.errbutton {
		background: colors.$ts-color-red-50;
		border: colors.$ts-color-red-50;
		color: colors.$white;
		&:hover {
			background: colors.$ts-color-red-25;
			box-shadow: none;
		}
	}
}

@media (max-width: 768px) {
	.errorBox {
		padding: 24px !important;
	}
}
