@use '../../../theme/colors';

@import '../../../theme/typography';

.meeting {
	&-requests {
		background: colors.$ts-color-blue-10;
		padding: 32px;
		border-radius: 10px;
		min-height: 190px;

		& > .meeting__detail:first-child {
			margin-top: 0 !important;
		}
	}

	&__title {
		font-weight: 600;
	}

	&__status {
		font-weight: 300;
	}

	&__date-time {
		font-size: 14px;
	}

	&__show-btn {
		&[aria-expanded='false'] {
			.more {
				display: inline;
			}

			.less {
				display: none;
			}
		}

		&[aria-expanded='true'] {
			.more {
				display: none;
			}
			.less {
				display: inline;
			}
		}
	}
}
